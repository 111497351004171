var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"justify-center px-0 py-0",staticStyle:{"height":"100%","background":"white"},attrs:{"fluid":""}},[_c('v-row',{style:({
      backgroundImage: 'radial-gradient(at top left, #bd054b, #790431)',
      backgroundSize: _vm.$vuetify.breakpoint.xsOnly ? 'auto 100%' : '100%',
      maskImage:
        'url(' +
        require(_vm.$vuetify.breakpoint.xsOnly
          ? '@/assets/home_header_mask_tall.png'
          : '@/assets/home_header_mask.png') +
        '), none',
      maskSize: '100% 100%',
      paddingTop: '128px',
      paddingBottom: '128px',
    })}),_c('faculty-detail',{attrs:{"member":_vm.giselle}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }